/**
 * Main Blog listing page component
 * Displays a grid of blog posts with featured header image
 */
import React, { useEffect, useState } from 'react';
import BlogPost from '../components/BlogPost';
import { getAllBlogPosts } from '../services/blogService';

const Blog = () => {
  // State to store blog posts data
  const [blogPosts, setBlogPosts] = useState([]);

  // Fetch blog posts on component mount
  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await getAllBlogPosts();
      setBlogPosts(posts);
    };
    fetchPosts();
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <section className="h-[40vh] md:h-[50vh] relative flex items-center justify-center">
        <img
          src="/img/blog-header.jpg"
          alt="Miami Beach"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/70" />
        <div className="relative z-10 text-center text-white space-y-6">
          <h1 className="text-5xl md:text-6xl mt-10 font-regular">DISCOVER LUXURI</h1>
        </div>
      </section>

      {/* Blog Posts Grid */}
      <div className="container mx-auto px-4 py-16 grid grid-cols-1 md:grid-cols-2 gap-8 xl:grid-cols-3">
        {blogPosts.map((post, index) => (
          <BlogPost key={index} {...post} />
        ))}
      </div>
    </div>
  );
};

export default Blog;
