import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight, CirclePlus, CircleMinus, Wifi, Tv, Phone, RefrigeratorIcon, AccessibilityIcon, BedDouble } from 'lucide-react';
import Button from './components/ui/button.js'; 
import { Envelope, InstagramLogo, LinkedinLogo, FacebookLogo, XLogo, MapPin } from '@phosphor-icons/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter as Router, Routes, Route, useNavigate, useParams, useLocation } from 'react-router-dom';
import './custom.css';
import { Snowflake, Coffee, Dumbbell, Wine, UtensilsCrossed } from 'lucide-react';
import Guide from './Guide'; 
import Blog from './features/blog/pages/Blog'; 
import { Blog1, Blog2, Blog3 } from './features/blog/pages/BlogPosts';
import ConciergePage from './features/concierge/pages/ConciergePage';
import ContactPage from './features/contact/pages/ContactPage';
import PrivacyPolicy from './features/legal/pages/PrivacyPolicy';
import TermsConditions from './features/legal/pages/TermsConditions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Helmet } from 'react-helmet';
// import HotelManager from './HotelManager';  
const hotels = [
  {
    id: 1,
    name: '852 Collins Ave',
    description: 'Hotel 852 on Collins Avenue is a boutique accommodation offering a blend of comfort and style in the heart of the city. With its sophisticated design, the hotel features well-appointed rooms, a variety of modern amenities, and exceptional service.',
    image: '/img/s3.jpg?height=800&width=1200',
    type: 'hotel',
    price: 116,
    amenities: ['WiFi', 'AC', 'Coffee', 'Gym', 'Bar', 'Restaurant'],
    maxOccupancy: 2,
    rooms: [
      {
        id: 1,
        name: 'King Suite with Balcony',
        description: 'This elegant suite features a private balcony offering stunning views. The suite includes a comfortable king-size bed, modern amenities, and a luxurious bathroom with walk-in shower. Perfect for couples seeking a premium experience with outdoor space.',
        images: [
          '/img/852-collins/King-Suite-with-Balcony/576505754.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576505738.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576505752.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576505753.jpg',
       
          '/img/852-collins/King-Suite-with-Balcony/576508106.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508111.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508113.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508116.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508119.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508120.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508127.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508136.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508145.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508153.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508156.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508166.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508168.jpg',
          '/img/852-collins/King-Suite-with-Balcony/576508172.jpg'
        ],
        price: 185,
        amenities: ['Queen Bed', '42" TV', 'Telephone', 'Mini Fridge', 'Free Wifi', 'Accessible'],
        maxOccupancy: 2,
        breakfast: 20,
        cancellation: '48 hours',
        view: 'City view with balcony'
      },
      {
        id: 2,
        name: 'Superior Family Room',
        description: 'Spacious family room designed for comfort and convenience. Features multiple sleeping areas, a modern bathroom, and all essential amenities to ensure a pleasant stay for families. The room provides ample space for both relaxation and family activities.',
        images: [
          '/img/852-collins/Superior-Family-Room/576508109.jpg',
          '/img/852-collins/Superior-Family-Room/576508094.jpg',
          '/img/852-collins/Superior-Family-Room/576508095.jpg',
          '/img/852-collins/Superior-Family-Room/576508098.jpg',
          '/img/852-collins/Superior-Family-Room/576508101.jpg',
       
          '/img/852-collins/Superior-Family-Room/576508110.jpg',
          '/img/852-collins/Superior-Family-Room/576508112.jpg',
          '/img/852-collins/Superior-Family-Room/576508132.jpg',
          '/img/852-collins/Superior-Family-Room/576508159.jpg'
        ],
        price: 165,
        amenities: ['Queen Bed', '42" TV', 'Telephone', 'Mini Fridge', 'Free Wifi', 'Accessible'],
        maxOccupancy: 4,
        breakfast: 20,
        cancellation: '48 hours',
        view: 'City view'
      },
      {
        id: 3,
        name: 'King Studio',
        description: 'The studio provides air conditioning, a private entrance, as well as a private bathroom featuring a walk-in shower and a hairdryer. Rooms on the upper floors are reachable by lift. The unit offers 1 bed.',
        images: [
          '/img/852-collins/King-Studio/576508133.jpg',
          '/img/852-collins/King-Studio/576505735.jpg',
          '/img/852-collins/King-Studio/576505738.jpg',
          '/img/852-collins/King-Studio/576505741.jpg',
          '/img/852-collins/King-Studio/576505749.jpg',
          '/img/852-collins/King-Studio/576505752.jpg',
          '/img/852-collins/King-Studio/576505756.jpg',
          '/img/852-collins/King-Studio/576505763.jpg',
       
        
          '/img/852-collins/King-Studio/576508173.jpg',
          '/img/852-collins/King-Studio/576508176.jpg'
        ],
        price: 145,
        amenities: ['Queen Bed', '42" TV', 'Telephone', 'Mini Fridge', 'Free Wifi', 'Accessible'],
        maxOccupancy: 2,
        breakfast: 20,
        cancellation: '48 hours',
        view: 'City view'
      },
      {
        id: 4,
        name: 'Luxury Suite',
        description: 'This spacious suite features 1 living room, 4 separate bedrooms and 2 bathrooms with a walk-in shower and free toiletries. In the well-fitted kitchen, guests will find a stovetop, a refrigerator, a dishwasher and an oven. The suite features air conditioning, a private entrance, a coffee machine, a dining area and a flat-screen TV.',
        images: [
          '/img/852-collins/Luxury-Suite/603403478.jpg',
          '/img/852-collins/Luxury-Suite/603393463.jpg',
          '/img/852-collins/Luxury-Suite/603393588.jpg',
          '/img/852-collins/Luxury-Suite/603393659.jpg',
          '/img/852-collins/Luxury-Suite/603394137.jpg',
          '/img/852-collins/Luxury-Suite/603394455.jpg',
          '/img/852-collins/Luxury-Suite/603395004.jpg',
          '/img/852-collins/Luxury-Suite/603395121.jpg',
          '/img/852-collins/Luxury-Suite/603397578.jpg',
          '/img/852-collins/Luxury-Suite/603397782.jpg',
          '/img/852-collins/Luxury-Suite/603398718.jpg',
          '/img/852-collins/Luxury-Suite/603401891.jpg',
          
          '/img/852-collins/Luxury-Suite/603403579.jpg'
        ],
        price: 145,
        amenities: ['Queen Bed', '42" TV', 'Telephone', 'Mini Fridge', 'Free Wifi', 'Accessible'],
        maxOccupancy: 2,
        breakfast: 20,
        cancellation: '48 hours',
        view: 'City view'
      }
    ]
  },
  {
    id: 2,
    name: 'Hotel Ocean',
    description: 'Hotel Ocean receives accolades for its impeccably clean rooms and facilities, thanks to its diligent housekeeping. The hotel\'s vibrant atmosphere is amplified by live music and stunning ocean views, making it a hit among guests.',
    image: '/img/s4.jpg?height=800&width=1200',
    type: 'hotel',
    price: 145,
    amenities: ['WiFi', 'AC', 'Coffee', 'Pool', 'Bar', 'Restaurant'],
    maxOccupancy: 3,
    rooms: [
      {
        id: 1,
        name: 'Modern Superior',
        description: 'The Modern Superior room features a plush king bed with premium linens, mini fridge, 42-inch flat screen television, personal climate control, wireless internet access.',
        images: [
          '/img/hotel-ocean/Modern-Superior/MIB_3932.jpg', // Cover Image
         
          '/img/hotel-ocean/Modern-Superior/MIB_3906.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3913.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3921.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3929.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3885.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3892.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3899.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3941.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3949.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3955.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3962.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3970.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3977.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3983.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3991.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_3999.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4006.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4013.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4019.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4027.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4034.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4040.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4047.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4054.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4061.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4068.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4075.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4083.jpg',
          '/img/hotel-ocean/Modern-Superior/MIB_4088.jpg'
        ],
        price: 116,
        amenities: ['Queen Bed', '42" TV', 'Telephone', 'Mini Fridge', 'Free Wifi', 'Accessible'],
        maxOccupancy: 2,
        breakfast: 20,
        cancellation: '48 hours',
        view: 'City view'
      },
      {
        id: 2,
        name: 'Modern Superior ADA',
        description: 'The Modern Superior room features a plush king bed with premium linens, mini fridge, 42-inch flat screen television, personal climate control, wireless internet access.',
        images: [
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3040.jpg', 
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3011.jpg',// Cover Image
       
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_2990.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_2998.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3005.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3019.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3026.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3032.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3047.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3053.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3060.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3068.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3074.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3082.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3088.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3095.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3102.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3109.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3116.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3124.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3131.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3138.jpg',
          '/img/hotel-ocean/Modern-Superior-ADA/MIB_3144.jpg'
        ],
        price: 145,
        amenities: ['King Bed', '42" TV', 'Minibar', 'Free Wifi', 'Accessible'],
        maxOccupancy: 2,
        breakfast: 20,
        cancellation: '48 hours',
        view: 'City view'
      },
      {
        id: 3,
        name: 'Deluxe Superior w/bathtub',
        description: 'The superior room features a plush King bed with premium linens, mini fridge, 42-inch flat screen television, personal climate control and wireless internet access.',
        images: [
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3179.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3186.jpg',// Cover Image
         
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3200.jpg', 
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3151.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3158.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3165.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3173.jpg',
   
        
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3193.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3207.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3215.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3221.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3228.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3235.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3242.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3249.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3256.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3263.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3270.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3276.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3284.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3291.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3298.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3305.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3313.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3319.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3326.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3333.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3340.jpg',
          '/img/hotel-ocean/Deluxe-Superior-wbathtub/MIB_3347.jpg'
        ],
        price: 145,
        amenities: ['King Bed', '42" TV', 'Telephone', 'Mini Fridge', 'Free Wifi'],
        maxOccupancy: 2,
        breakfast: 20,
        cancellation: '48 hours',
        view: 'City view'
      },
      {
        id: 4,
        name: 'Modern Oceanfront',
        description: 'The Modern Oceanfront room features a plush king bed with premium linens, mini fridge, 42-inch flat screen television, personal climate control, wireless internet access and stunning views of the Atlantic Ocean.',
        images: [
          '/img/hotel-ocean/Modern-oceanfront/MIB_3410.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3368.jpg', // Cover Image
          '/img/hotel-ocean/Modern-oceanfront/MIB_3354.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3360.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3375.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3382.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3390.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3395.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3404.jpg',
         
          '/img/hotel-ocean/Modern-oceanfront/MIB_3417.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3423.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3431.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3437.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3445.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3452.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3459.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3466.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3473.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3480.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3487.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3494.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3501.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3508.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3515.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3522.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3529.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3536.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3541.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3550.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3556.jpg',
          '/img/hotel-ocean/Modern-oceanfront/MIB_3564.jpg'
        ],
        price: 145,
        amenities: ['King Bed', '42" TV', 'Telephone', 'Mini Fridge', 'Free Wifi'],
        maxOccupancy: 2,
        breakfast: 20,
        cancellation: '48 hours',
        view: 'Ocean view'
      },
      {
        id: 5,
        name: 'Balcony With Ocean View',
        description: 'The Modern Oceanfront room features a plush king bed with premium linens, mini fridge, 42-inch flat screen television, personal climate control, wireless internet access and stunning balcony views of the Atlantic Ocean.',
        images: [
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3748.jpg', 
          
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3690.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3697.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3705.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3711.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3718.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3725.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3775.jpg',// Cover Image
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3684.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3733.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3740.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3745.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3755.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3762.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3781.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3789.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3803.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3810.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3816.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3823.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3831.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3838.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3844.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3851.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3858.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3865.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3873.jpg',
          '/img/hotel-ocean/Balcony-wtih-ocean-view/MIB_3878.jpg'
        ],
        price: 145,
        amenities: ['King Bed', '42" TV', 'Telephone', 'Mini Fridge', 'Free Wifi'],
        maxOccupancy: 2,
        breakfast: 20,
        cancellation: '48 hours',
        view: 'Ocean view'
      }
      
    ]
  }
];
const suites = [];  

const testimonials = [
  {
    id: 1,
    name: 'Justin',
    text: 'The perfect place to stay if you\'re looking to be on the beach and walking distance to absolutely everything one needs while on vacation, from dining to cafes to lounges. I would stay here again without a doubt.',
    image: '/img/testi1.png',
    date: 'March 2024'
  },
  {
    id: 2,
    name: 'Marianne',
    text: 'Place was as described. Definitely recommended if you want to stay in the Miami Beach area.',
    image: '/img/testi2.png',
    date: 'March 2024'
  },
  {
    id: 3,
    name: 'Xiaoli',
    text: 'The location of the property is great! Walking to the beach and restaurant. Small, cozy entry lobby looking nice. Room is simple but very organized and clean. Bathroom is simple but clean too. King size bed as described very nice and comfy.',
    image: '/img/testi3.png',
    date: 'March 2024'
  },
  {
    id: 4,
    name: 'Camila F',
    text: 'Honestly felt like in a hotel but with a plus of quietness and privacy. We loved it! Very clean and had all you need. Good for short stays.',
    image: '/img/testi4.png',
    date: 'March 2024'
  },
  {
    id: 5,
    name: 'Keimani',
    text: 'Highly recommend! right near the strip where everything is going on & many restaurants so you’re not having to spend money on ubers, place looked exactly like pictures and was very clean. Definitely looking forward to booking again!',
    image: '/img/testi5.png',
    date: 'March 2024'
  }
];

const Header = ({ onMenuClick }) => (
  <header className="w-screen flex lg:flex-row flex-col justify-between py-3 px-6 text-white bg-black fixed z-30 bg-opacity-75 ">
    <div className="full-container mx-auto px-4 h-16 flex items-center justify-between w-full mobile-menu-style">
    
        <a href="/" className="h-8 cursor-pointer px-10" style={{height: '35px'}}>
          <img src="/img/Luxuri-Logo.svg" alt="Luxuri" className="h-full" />
        </a>
        <nav className="hidden md:flex space-x-8 w-full justify-around px-4 md:px-4 lg:px-10 xl:px-12">
          <a href="https://shop.luxuri.com/" target="_blank" className="font-normal text-gray-300 text-base leading-6 hover:text-white tracking-1.2px">
            MERCH
          </a>
          <a href="/guide" className="font-normal text-gray-300 text-base leading-6 hover:text-white tracking-1.2px">
            GUIDE
          </a>
          <a href="/blog" className="font-normal text-gray-300 text-base leading-6 hover:text-white tracking-1.2px">
            BLOG
          </a>
          <a href="/#testimonials" className="font-normal text-gray-300 text-base leading-6 hover:text-white tracking-1.2px">
            TESTIMONIALS
          </a>
          <a href="/concierge" className="font-normal text-gray-300 text-base leading-6 hover:text-white tracking-1.2px">
            CONCIERGE
          </a>
        </nav>
        <button onClick={onMenuClick} className="md:hidden text-gray-300 hover:text-white">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
     
    </div>
  </header>
 
);

const MobileNav = ({ isOpen, onClose }) => (
  <div
    className={`fixed inset-x-0 top-0 bg-black/70 z-50 transform transition-transform duration-300 ${
      isOpen ? 'translate-y-0' : '-translate-y-full'
    }`}
  >
    <div className="flex justify-between items-center p-4 mobile-menu-style">
      <a href="/" className="h-8 cursor-pointer px-10" style={{height: '35px'}}>
        <img src="/img/Luxuri-Logo.svg" alt="Luxuri" className="h-full" />
      </a>
      <button onClick={onClose} className="text-gray-300 pr-3">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <nav className="flex flex-col items-center space-y-7 py-10">
      <a href="https://shop.luxuri.com/" target="_blank" className="text-2xl  text-gray-300 hover:text-white">MERCH</a>
      <a href="/guide" className="text-2xl  text-gray-300 hover:text-white">GUIDE</a>
      <a href="/blog" className="text-2xl  text-gray-300 hover:text-white">BLOG</a>
      <a href="/#testimonials" className="text-2xl  text-gray-300 hover:text-white">TESTIMONIALS</a>
      <a href="/concierge" className="text-2xl  text-gray-300 hover:text-white">CONCIERGE</a>
    </nav>
  </div>
);

const HeroSection = () => (
  <section className="h-screen relative flex items-center justify-center">
    <img src="/img/hero.png?height=1080&width=1920" alt="Hero" className="absolute inset-0 w-full h-full object-cover" />
    <div className="absolute inset-0 bg-black/50" />
    <div className="relative z-10 text-center text-white space-y-6 featured-logo-n">
    <img src="/img/Luxuri-Logo.svg" alt="Luxuri" />
      <p className="text-3xl md:text-4xl ">SUITES</p>
      <Button  onClick={() => window.location.href = '/#featured'}  className="text-lg rounded-full py-[4px] px-6 bg-white opacity-75 text-black font-semibold explore-button">
        EXPLORE
      </Button>
    </div>
  </section>
);

const FeaturedSuitesSectionWrapper = ({ currentHotel, nextHotel, prevHotel }) => {
  const navigate = useNavigate();
  
  return (
    <FeaturedSuitesSection 
      currentHotel={currentHotel} 
      nextHotel={nextHotel} 
      prevHotel={prevHotel} 
      navigate={navigate}
    />
  );
};

const FeaturedSuitesSection = ({ currentHotel, nextHotel, prevHotel, navigate }) => {
  const allProperties = [...hotels, ...suites];
  const currentProperty = allProperties[currentHotel];

  const handleReserve = () => {
    navigate(`/suites/${currentProperty.id}`);
  };

  return (
    <section className="bg-black text-white py-16" id="featured">
      <div className="full-container mx-auto px-4 featured-suites-section-hp">
        <h2 className="text-4xl md:text-5xl font-light mb-10 md:mb-5 pl-0 md:pl-5 lg:pl-5 text-center md:text-left ">
        Featured <span className="font-bold">SUITES</span>
        </h2>
        <div className="grid md:grid-cols-2 gap-8 items-center">
          <div className="relative aspect-[4/3] overflow-hidden rounded-lg">
            <img src={currentProperty.image} alt={currentProperty.name} className="absolute inset-0 w-full h-full object-cover" />
          </div>
          <div className="space-y-6 p-5 md:p-10 flex flex-col justify-between h-full">
            <div>
              <h3 className="text-5xl md:text-6xl pb-5 font-bold ">{currentProperty.name}</h3>
              <p className="text-gray-400 leading-relaxed text-xl">{currentProperty.description}</p>
            </div>
           
            <div className="flex items-center space-x-4 justify-between">
              <div className='flex'>
              <Button
                variant="outline"
                size="icon"
                onClick={prevHotel}
                className="text-white border-white hover:bg-white hover:text-black npbutton"
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="icon"
                onClick={nextHotel}
                className="text-white border-white hover:bg-white hover:text-black npbutton"
              >
                <ChevronRight className="h-4 w-4" />
              </Button> </div>
              <div className="ml-auto flex items-center gap-4">
               
                <Button variant="default" onClick={handleReserve} className="text-lg  py-[4px] px-6 text-white font-semibold Reserve-Now">
                  Reserve Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ConciergeSection = () => (
  <section className="relative h-screen flex items-center justify-center">
    <div 
      className="absolute inset-0 w-full h-full"
      style={{
        backgroundImage: `
          linear-gradient(rgba(0, 0, 0, 0) 80%, rgb(0, 0, 0)), 
          linear-gradient(to top, rgba(0, 0, 0, 0) 80%, rgb(0, 0, 0)), 
          url(/img/image1.jpg)
        `,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    />
    <div className="absolute inset-0 bg-black/60" />
    <div className="relative z-10 text-center text-white space-y-6 max-w-3xl mx-auto px-4">
      <h2 className="text-5xl md:text-7xl font-bold tracking-tight">CONCIERGE</h2>
   
      <Button 
        variant="outline" 
        onClick={() => window.location.href = '/concierge'} 
        className="text-lg rounded-full py-[4px] px-6 bg-white opacity-75 text-black font-semibold explore-button"
      >
        EXPLORE
      </Button>
    </div>
  </section>
);

const TestimonialsSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="py-16 bg-white" id="testimonials">
      <div className="container mx-auto px-4">
      <div className="flex py-4">
            <div className="border-b-[1px] border-[#A7A098] leading-[0.1em] w-1/5 mt-[10px] mb-[20px]"></div>
            <h2 className="strike-text">
              <span className="font-bold ">TESTIMONIALS</span>
            </h2>
          </div>
        
        <Slider {...settings} className="testimonials-slider">
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="px-4">
              <div className="bg-[#1C1C1C] rounded-lg p-8 h-[300px] flex flex-col">
                <div className="flex items-center gap-4 mb-4">
                  <img 
                    src={testimonial.image} 
                    alt={testimonial.name} 
                    className="w-12 h-12 rounded-full object-cover" 
                  />
                  <div>
                    <h3 className="text-white font-medium">{testimonial.name}</h3>
                    <div className="flex text-yellow-400">★★★★★</div>
                  </div>
                </div>
                <p className="text-gray-300 mb-4 flex-1 overflow-y-auto">{testimonial.text}</p>
                <p className="text-sm text-gray-400">{testimonial.date}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  
  return (
    <footer className="bg-black text-white pt-16 pb-8 border-t-1 border-white border-opacity-50">
      <div className="container mx-auto px-4">
        <div className="mb-8">
          <h2 className="text-4xl font-bold mb-2">Luxuri Suites</h2>
          <p className="text-xl">Get in touch</p>
        </div>

        <div className="flex space-x-6 mb-12">
          <a href="mailto:booking@luxuri.com" target="_blank" className="hover:opacity-75">
            <Envelope size={24} />
          </a>
          <a href="https://www.instagram.com/luxuri/" target="_blank" className="hover:opacity-75">
            <InstagramLogo size={24} />
          </a>
          <a href="https://www.linkedin.com/company/luxuri/" target="_blank" className="hover:opacity-75">
            <LinkedinLogo size={24} />
          </a>
          <a href="https://www.facebook.com/luxurirentals" target="_blank" className="hover:opacity-75">
            <FacebookLogo size={24} />
          </a>
          <a href="https://x.com/luxuritweets" target="_blank" className="hover:opacity-75">
            <XLogo size={24} />
          </a>
        </div>

        <div className="flex justify-between text-sm text-gray-400">
          <button onClick={() => navigate('/privacy-policy')} className="hover:text-white pr-1">
            Privacy Policy
          </button>
          <button onClick={() => navigate('/terms-conditions')} className="hover:text-white pr-1">
            Terms & Conditions
          </button>
          <button onClick={() => navigate('/contact')} className="hover:text-white pr-1">
            Contact
          </button>
          <a href="/sitemap.xml" className="hover:text-white pr-1">
            Sitemap
          </a>
          <span>2024 © LUXURI</span>
        </div>
      </div>
    </footer>
  );
};

const BookingSection = () => {
  const { id } = useParams();
  const allProperties = [...hotels, ...suites];
  const currentProperty = allProperties.find(property => property.id === parseInt(id)) || allProperties[0];
  const rooms = currentProperty.rooms || [];
  
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const [showCheckInPicker, setShowCheckInPicker] = useState(false);
  const [showCheckOutPicker, setShowCheckOutPicker] = useState(false);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleCheckInClick = () => {
    setShowCheckOutPicker(false);
    setShowCheckInPicker(true);
  };

  const handleCheckOutClick = () => {
    setShowCheckInPicker(false);
    setShowCheckOutPicker(true);
  };

  const handleCheckInChange = (date) => {
    setCheckInDate(date);
    setShowCheckInPicker(false);
    if (checkOutDate <= date) {
      setCheckOutDate(new Date(date.setDate(date.getDate() + 1)));
    }
  };

  const handleCheckOutChange = (date) => {
    setCheckOutDate(date);
    setShowCheckOutPicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const datepickerContainer = document.querySelector('.datepicker-container');
      if (datepickerContainer && !datepickerContainer.contains(event.target)) {
        setShowCheckInPicker(false);
        setShowCheckOutPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full px-5 bg-[#F0F0F0] pt-10 py-5 md:pt-0 md:py-0">
      <div className="mb-8">
        <h3 className="text-5xl md:text-6xl pt-6 md:pt-12 lg:pt-20 pb-5 font-bold mb-5 md:mb-5">
          {currentProperty.name}
        </h3>
        
        <div className="datepicker-container flex items-center justify-center bg-white rounded-lg shadow-md p-4 mb-6 max-w-md relative">
          <button 
            onClick={handleCheckInClick}
            className="flex items-center gap-2 hover:bg-gray-50 p-2 rounded-lg transition-colors"
          >
            <MapPin size={28} color="grey" weight="regular" />
            <div className="text-left">
              <p className="font-medium text-black">Check-in Date</p>
              <p className="text-gray-600">{formatDate(checkInDate)}</p>
            </div>
          </button>
          
          <div className="w-px h-12 bg-gray-200 mx-4 md:mx-8" />
          
          <button 
            onClick={handleCheckOutClick}
            className="flex items-center gap-2 hover:bg-gray-50 p-2 rounded-lg transition-colors"
          >
            <MapPin size={28} color="grey" weight="regular" />
            <div className="text-left">
              <p className="font-medium text-black">Check-out Date</p>
              <p className="text-gray-600">{formatDate(checkOutDate)}</p>
            </div>
          </button>

          {showCheckInPicker && (
            <div className="absolute top-full left-0 mt-2 z-50 bg-white shadow-lg rounded-lg">
              <DatePicker
                selected={checkInDate}
                onChange={handleCheckInChange}
                minDate={new Date()}
                inline
              />
            </div>
          )}

          {showCheckOutPicker && (
            <div className="absolute top-full right-0 mt-2 z-50 bg-white shadow-lg rounded-lg">
              <DatePicker
                selected={checkOutDate}
                onChange={handleCheckOutChange}
                minDate={new Date(checkInDate.getTime() + 86400000)}
                inline
              />
            </div>
          )}
        </div>
      </div>

      {rooms.map((room) => (
        <RoomCard key={room.id} room={room} />
      ))}
    </div>
  );
};

const RoomCard = ({ room }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [adults, setAdults] = useState(1);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const handlePrevImage = () => {
    setCurrentImage((prev) => (prev === 0 ? room.images.length - 1 : prev - 1));
  };

  const handleNextImage = () => {
    setCurrentImage((prev) => (prev === room.images.length - 1 ? 0 : prev + 1));
  };

  const handleIncrement = (setter) => {
    setter(prev => Math.min(prev + 1, 5));
  };

  const handleDecrement = (setter) => {
    setter(prev => Math.max(prev - 1, 1));
  };

  const calculatePrice = () => {
    const roomMultiplier = rooms * 1.0;
    const adultMultiplier = adults > 1 ? 1 + ((adults - 1) * 0.3) : 1;
    return Math.round(room.price * roomMultiplier * adultMultiplier);
  };

  const pricePerNight = calculatePrice();

  const slides = room.images.map(image => ({ src: image }));

  return (
    <div className="room-card">
      <div className="room-card-container">
        <div className="room-image-section relative w-full h-[200px] sm:h-[250px] md:h-[350px] rounded-2xl overflow-hidden">
          <img 
            src={room.images[currentImage]} 
            alt={room.name} 
            className=" w-full h-full object-cover cursor-pointer" 
            onClick={() => setLightboxOpen(true)}
          />
          {/* Image navigation buttons */}
          <button onClick={handlePrevImage} className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/80 p-1 rounded-full hover:bg-white transition-colors">
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button onClick={handleNextImage} className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/80 p-1 rounded-full hover:bg-white transition-colors">
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>

        <Lightbox
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={slides}
          index={currentImage}
          styles={{ container: { backgroundColor: "rgba(0, 0, 0, .9)" } }}
          controller={{ closeOnBackdropClick: true }}
        />
        <div className="room-details-section ">
          <h2 className="text-3xl md:text-4xl mb-4">{room.name}</h2>
          <p className="text-gray-600 mb-3">{room.description}</p>
        
          <div className="flex flex-row flex-wrap gap-4 lg:flex-col lg:gap-2 pt-2">
            {room.amenities.map((amenity) => {
              let icon;
              switch (amenity) {
                case 'Queen Bed':
                case 'King Bed':
                  icon = <BedDouble className="w-5 h-5" />;
                  break;
                case '42" TV':
                case 'Flat-screen TV':
                  icon = <Tv className="w-5 h-5" />;
                  break;
                case 'Telephone':
                  icon = <Phone className="w-5 h-5" />;
                  break;
                case 'Mini Fridge':
                case 'Minibar':
                  icon = <RefrigeratorIcon className="w-5 h-5" />;
                  break;
                case 'Free Wifi':
                case 'WiFi':
                  icon = <Wifi className="w-5 h-5" />;
                  break;
                case 'Accessible':
                  icon = <AccessibilityIcon className="w-5 h-5" />;
                  break;
                default:
                  icon = null;
              }
              return (
                <div key={amenity} className="flex items-center gap-2 text-gray-800">
                  {icon}
                  <span>{amenity}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="room-booking-section">
          <RoomBookingControls
            rooms={rooms}
            adults={adults}
            pricePerNight={pricePerNight}
            basePrice={room.price}
            onIncrementRooms={() => handleIncrement(setRooms)}
            onDecrementRooms={() => handleDecrement(setRooms)}
            onIncrementAdults={() => handleIncrement(setAdults)}
            onDecrementAdults={() => handleDecrement(setAdults)}
          />
        </div>
      </div>
    </div>
  );
};

const RoomBookingControls = ({ 
  rooms, 
  adults, 
  pricePerNight, 
  basePrice,
  onIncrementRooms,
  onDecrementRooms,
  onIncrementAdults,
  onDecrementAdults
}) => (
  <div className="booking-controls">
    <div className="booking-controls-container flex flex-inline ">
      <div className="w-1/3 md:w-1/2 flex flex-col justify-between bk-left">
        <div className="flex flex-col pb-4 align-center justify-between items-center">
          <p className="text-black mb-3">Select number of rooms</p>
          <div className="flex text-3xl font-bold items-center gap-4">
            <button onClick={onDecrementRooms} className="text-gray-600 hover:text-black transition-colors">
              <CircleMinus className="w-10 h-10" strokeWidth={1} />
            </button>
            <span className="w-4 text-center text-3xl font-bold text-black">{rooms}</span>
            <button onClick={onIncrementRooms} className="text-gray-600 hover:text-black transition-colors">
              <CirclePlus className="w-10 h-10" strokeWidth={1} />
            </button>
          </div>
        </div>
        <div className="flex flex-col pb-4 mt-0 md:mt-5 align-center justify-between items-center">
          <p className="text-black mb-3">Select number of adults</p>
          <div className="flex text-3xl font-bold items-center gap-4">
            <button onClick={onDecrementAdults} className="text-gray-600 hover:text-black transition-colors">
              <CircleMinus className="w-10 h-10" strokeWidth={1} />
            </button>
            <h2 className="w-4 text-3xl font-bold text-center text-black">{adults}</h2>
            <button onClick={onIncrementAdults} className="text-gray-600 hover:text-black transition-colors">
              <CirclePlus className="w-10 h-10" strokeWidth={1} />
            </button>
          </div>
        </div>
      </div>
      <div className="w-1/3 md:hidden"></div>
      <div className="w-1/3 md:w-1/2 flex flex-col justify-between my-auto h-full bk-right">
        <div className="flex flex-col justify-between items-center">
          <div>
            <p className="text-3xl font-bold text-black text-center mb-5">USD {pricePerNight}</p>
            <p className="text-gray-600 mb-2">USD {basePrice} per/night</p>
          </div>
          <button className="bg-black text-white px-6 py-2 rounded-full hover:bg-gray-800 transition-colors">
            RESERVE
          </button>
        </div>
      </div>
    </div>
  </div>
);

const SuitesPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { id } = useParams();
  
  // Find the current property based on the ID
  const allProperties = [...hotels, ...suites];
  const currentProperty = allProperties.find(property => property.id === parseInt(id)) || allProperties[0];

  const slides = currentProperty.name === 'Hotel Ocean' ? [
    currentProperty.image,
    '/img/s2.jpg',
    '/img/s3.jpg',
    '/img/s4.jpg',
    '/img/s5.jpg',
  ] : [
    '/img/s3.jpg',
    '/img/38.jpg',
    '/img/42.jpg',
    '/img/44.jpg',
    '/img/45.jpg',
  ];

  // Use the property's amenities to show relevant icons
  const icons = currentProperty.amenities.map(amenity => {
    const iconMap = {
      'WiFi': { Icon: Wifi, label: 'WiFi' },
      'AC': { Icon: Snowflake, label: 'AC' },
      'Coffee': { Icon: Coffee, label: 'Coffee' },
      'Gym': { Icon: Dumbbell, label: 'Gym' },
      'Bar': { Icon: Wine, label: 'Bar' },
      'Restaurant': { Icon: UtensilsCrossed, label: 'Restaurant' }
    };
    return iconMap[amenity] || { Icon: Coffee, label: amenity };
  });

  return (
    <div className="pt-18 md:pt-24  bg-black">
      <div className="flex flex-col lg:flex-row px-0 md:px-6 ">
        {/* Left Slider - Keep your existing code */}
        <div className="lg:w-3/5 h-[60vh] md:h-[80vh] relative">
          <img src={slides[currentSlide]} alt="Suite" className="absolute inset-0 w-full h-full object-cover" />
         
        </div>

        {/* Right Side */}
        <div className="lg:w-2/5">
          {/* Image Grid */}
          <div className="flex w-full flex-wrap">
            {slides.slice(1, 5).map((image, index) => (
              <div key={index} className="relative group w-1/4 md:w-1/2 h-[100px] md:h-[225px] mobile-image-overlay">
                <img src={image} alt="Suite" className="absolute inset-0 w-full h-full object-cover" />
                <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center md:block hidden">
                  <div className="text-white text-xl">
                    {/* Hover overlay content if needed */}
                  </div>
                </div>
              </div>
            ))}
          </div>


        

          {/* Icons Section */}
          <div className="w-full bg-black py-2  md:py-12 md:mt-5 icon-grid-container h-full md:max-h-80">
       
              {/* First row of icons */}
              <div className="grid grid-cols-6 gap-4 md:gap-12 w-full md:grid-cols-3 md:grid-rows-2 h-full p-4">
                {icons.slice(0, 6).map(({ Icon, label }, index) => (
                  <div key={index} className="flex items-center justify-center group relative">
                    <Icon 
                      weight="fill"
                      className="w-8 h-8 text-white group-hover:opacity-0 transition-opacity duration-300" 
                    />
                    <span className="text-white text-sm absolute opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      {label}
                    </span>
                  </div>
                ))}
              </div>
    
          </div>
        </div>
      </div>
      <div className=" flex py-6 px-0 md:px-6 d-none">
        <div className=" flex gap-4  suite-slider-controls-grp">
            <button 
              onClick={() => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)}
              className="text-white border-white hover:bg-white hover:text-black npbutton2"
            >
              <ChevronLeft className="h-6 w-6 text-white" />
            </button>
            <button 
              onClick={() => setCurrentSlide((prev) => (prev + 1) % slides.length)}
              className="text-white border-white hover:bg-white hover:text-black npbutton2"
            >
              <ChevronRight className="h-6 w-6 text-white" />
            </button>
          </div>

      </div>
    </div>
  );
};
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
const App = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentHotel, setCurrentHotel] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const allPropertiesLength = hotels.length + suites.length;
  const nextHotel = () => setCurrentHotel((prev) => (prev + 1) % allPropertiesLength);
  const prevHotel = () => setCurrentHotel((prev) => (prev - 1 + allPropertiesLength) % allPropertiesLength);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <Helmet>
          <title>Luxuri Suites | Luxury Hotels & Premium Accommodations</title>
          <meta name="description" content="Experience unparalleled luxury at Luxuri Suites. Our boutique hotels offer premium accommodations, world-class amenities, and exceptional service in prime locations. Book your perfect stay today." />
          <meta name="keywords" content="luxury hotels, boutique hotels, premium accommodations, luxury suites, hotel booking, 5-star hotels" />
          <meta property="og:title" content="Luxuri Suites | Luxury Boutique Hotels & Premium Accommodations" />
          <meta property="og:description" content="Experience unparalleled luxury at Luxuri Suites. Our boutique hotels offer premium accommodations, world-class amenities, and exceptional service in prime locations." />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://luxurisuites.com" />
        </Helmet>
        {!isMobileMenuOpen && <Header onMenuClick={toggleMobileMenu} />}
        <MobileNav isOpen={isMobileMenuOpen} onClose={toggleMobileMenu} />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <FeaturedSuitesSectionWrapper 
                currentHotel={currentHotel}
                nextHotel={nextHotel}
                prevHotel={prevHotel}
              />
              <ConciergeSection />
              <TestimonialsSection />
            </>
          } />
          <Route path="/suites/:id" element={
            <>
              <SuitesPage />
              <BookingSection />
            </>
          } />
          <Route path="/concierge" element={<ConciergePage />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/miami-beach-guide" element={<Blog1 />} />
          <Route path="/blog/hidden-gems-miami" element={<Blog2 />} />
          <Route path="/blog/miami-nightlife" element={<Blog3 />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;   