/**
 * Blog Service
 * Handles all blog-related data operations
 * Currently using mock data, can be replaced with actual API calls in the future
 */

// Mock data for blog posts
const blogPosts = [
  {
    id: 1,
    title: 'Sun, Sand, and Surf: The Ultimate Guide to Miami Beach\'s Beaches',
    date: '28.04.2024',
    image: '/img/blog/blog-1.jpg',
    slug: 'miami-beach-guide'
  },
  {
    id: 2,
    title: 'Beyond the Beach: Hidden Gems and Local Hangouts in Miami Beach',
    date: '14.02.2024',
    image: '/img/blog/blog-2.jpg',
    slug: 'hidden-gems-miami'
  },
  {
    id: 3,
    title: 'Nightlife Vibes: The Hottest Clubs and Bars in Miami Beach',
    date: '05.01.2024',
    image: '/img/blog/blog-3.jpg',
    slug: 'miami-nightlife'
  }
];

/**
 * Get all blog posts
 * @returns {Promise<Array>} Array of blog post objects
 */
export const getAllBlogPosts = () => {
  return Promise.resolve(blogPosts);
};

/**
 * Get a specific blog post by its slug
 * @param {string} slug - The URL slug of the blog post
 * @returns {Promise<Object|null>} Blog post object or null if not found
 */
export const getBlogPostBySlug = (slug) => {
  const post = blogPosts.find(post => post.slug === slug);
  return Promise.resolve(post || null);
};

/* Future API implementation example:
export const getAllBlogPosts = async () => {
  const response = await fetch('/api/blog-posts');
  return response.json();
};

export const getBlogPostBySlug = async (slug) => {
  const response = await fetch(`/api/blog-posts/${slug}`);
  return response.json();
};
*/
