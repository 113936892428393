import React from 'react';
import { Envelope, InstagramLogo, LinkedinLogo, FacebookLogo, XLogo, MapPin } from '@phosphor-icons/react';

const ContactPage = () => {
  return (
    <div className="pt-18 md:pt-24 bg-black min-h-screen">
      {/* Hero Section */}
      <div className="relative h-[60vh] w-full">
        <div 
          className="absolute inset-0 w-full h-full"
          style={{
            backgroundImage: `
              linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
              url('/img/s1.jpg')
            `,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-5xl md:text-7xl text-white font-light tracking-tight">
            Contact Us
          </h1>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Contact Information */}
          <div className="text-white space-y-8">
            <h2 className="text-3xl font-light mb-6">Get in Touch</h2>
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <MapPin className="w-6 h-6" />
                <p>852 Collins Ave, Miami Beach, FL 33139</p>
              </div>
              <div className="flex items-center space-x-4">
                <Envelope className="w-6 h-6" />
                <p>info@luxurisuites.com</p>
              </div>
            </div>
            <div className="pt-8">
              <h3 className="text-xl font-light mb-4">Follow Us</h3>
              <div className="flex space-x-6">
                <a href="#" className="text-white hover:text-[#d7a55a] transition-colors">
                  <InstagramLogo className="w-6 h-6" />
                </a>
                <a href="#" className="text-white hover:text-[#d7a55a] transition-colors">
                  <FacebookLogo className="w-6 h-6" />
                </a>
                <a href="#" className="text-white hover:text-[#d7a55a] transition-colors">
                  <XLogo className="w-6 h-6" />
                </a>
                <a href="#" className="text-white hover:text-[#d7a55a] transition-colors">
                  <LinkedinLogo className="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>

          {/* Contact Form */}
          <div className="bg-white/5 p-8 rounded-2xl backdrop-blur-sm">
            <form className="space-y-6">
              <div className="space-y-2">
                <label className="text-white text-sm">Name</label>
                <input 
                  type="text" 
                  className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white focus:outline-none focus:border-[#d7a55a]"
                  placeholder="Your name"
                />
              </div>
              <div className="space-y-2">
                <label className="text-white text-sm">Email</label>
                <input 
                  type="email" 
                  className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white focus:outline-none focus:border-[#d7a55a]"
                  placeholder="Your email"
                />
              </div>
              <div className="space-y-2">
                <label className="text-white text-sm">Message</label>
                <textarea 
                  className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white focus:outline-none focus:border-[#d7a55a] h-32"
                  placeholder="Your message"
                ></textarea>
              </div>
              <button 
                type="submit" 
                className="w-full bg-[#d7a55a] text-black font-semibold py-3 rounded-lg hover:bg-[#c57600] transition-colors"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
