/**
 * ConciergeHero Component
 * Displays a full-screen hero section with pills button navigation
 */
import React, { useState, useRef } from 'react';

// Concierge services data
const services = [
  { title: 'IN-HOUSE GOURMET CHEF', image: '/img/Concierge/image1.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'YATCH CHARTER', image: '/img/Concierge/image2.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'PRIVATE JET', image: '/img/Concierge/image3.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'PRIVATE DRIVER', image: '/img/Concierge/image4.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'IN-HOUSE MASSAGE', image: '/img/Concierge/image5.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'RESTAURANT ACCESS', image: '/img/Concierge/image6.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'EXCLUSIVE EVENT ACCESS', image: '/img/Concierge/image7.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'IN-HOUSE FACIALS', image: '/img/Concierge/image8.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'EVENT PLANNER', image: '/img/Concierge/image9.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'DAILY CLEANING', image: '/img/Concierge/image10.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'HELI SKIING', image: '/img/Concierge/image11.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'AIRPORT TRANSFER', image: '/img/Concierge/image12.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'PHOTOGRAPHER', image: '/img/Concierge/image13.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'NIGHTCLUB/LOUNGE ACCESS', image: '/img/Concierge/image14.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'PERSONAL SHOPPER', image: '/img/Concierge/image15.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'SPRINTER SERVICES', image: '/img/Concierge/image16.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'PRIVATE SECURITY', image: '/img/Concierge/image17.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'BABY SITTER / NANNY', image: '/img/Concierge/image18.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
  { title: 'JET SKI RENTALS', image: '/img/Concierge/image19.jpg', description: 'Luxuri prides itself on complete discretion when it comes to our clients.' },
];

const ConciergeHero = () => {
  const [currentService, setCurrentService] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const sliderRef = useRef(null);

  const handleDragStart = (e) => {
    setIsDragging(true);
    setStartX(e.type === 'mousedown' ? e.pageX : e.touches[0].pageX);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleDragMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.type === 'mousemove' ? e.pageX : e.touches[0].pageX;
    const walk = (startX - x) * 2;
    sliderRef.current.scrollLeft = scrollLeft + walk;
  };

  return (
    <section className="h-[100vh] relative flex flex-col items-center justify-between">
      {/* Hero image with gradient overlays */}
      <div 
        className="absolute inset-0 w-full h-full"
        style={{
          backgroundImage: `
            linear-gradient(rgba(0, 0, 0, 0) 80%, rgb(0, 0, 0)), 
            linear-gradient(to top, rgba(0, 0, 0, 0) 80%, rgb(0, 0, 0)), 
            url(${services[currentService].image})
          `,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transition: 'background-image 0.5s ease-in-out'
        }}
      />
      <div className="absolute inset-0 bg-black/10" />
      
      {/* Title and Description */}
      <div className="relative z-10 text-center text-white space-y-6 mt-32">
        <h1 className="text-5xl md:text-7xl font-light tracking-tight mt-5 concierge-hero-title text-shadow-lg">
          {services[currentService].title}
        </h1>
        <p className="text-xl md:text-2xl max-w-xl mx-auto px-4">
          {services[currentService].description}
        </p>
      </div>

      {/* Pills Navigation with touch/drag functionality */}
      <div className="relative z-10 w-full pb-8">
        <div
          ref={sliderRef}
          className="flex justify-between overflow-x-scroll px-2 no-scrollbar cursor-grab active:cursor-grabbing"
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
          onMouseMove={handleDragMove}
          onTouchStart={handleDragStart}
          onTouchEnd={handleDragEnd}
          onTouchMove={handleDragMove}
        >
          {services.map((service, index) => (
            <button
              key={index}
              onClick={() => !isDragging && setCurrentService(index)}
              className={`h-fit rounded-2xl bg-[#d7a55a] text-black items-center font-semibold text-xs se:md py-2 px-4 w-fit cursor-pointer m-2 hover:bg-[#c57600] whitespace-nowrap font-helvetica ${
                currentService === index ? 'bg-[#c57600]' : ''
              }`}
            >
              {service.title}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ConciergeHero;
