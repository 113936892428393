import React from 'react';

const TermsConditions = () => {
  return (
    <div className="pt-18 md:pt-24 bg-black min-h-screen">
      <div className="container mx-auto px-4 py-16 text-white">
        <h1 className="text-5xl md:text-7xl font-light mb-12">Terms & Conditions</h1>
        <div className="space-y-8 max-w-4xl">
          <section className="space-y-4">
            <h2 className="text-2xl font-light">1. Acceptance of Terms</h2>
            <p className="text-gray-300">
              By accessing and using our website and services, you agree to be bound by these Terms & Conditions
              and all applicable laws and regulations.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-light">2. Booking and Reservations</h2>
            <p className="text-gray-300">
              All bookings are subject to availability and confirmation. A valid credit card is required
              to secure your reservation. Cancellation policies vary by rate type and room category.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-light">3. Check-in and Check-out</h2>
            <p className="text-gray-300">
              Check-in time is from 3:00 PM, and check-out time is until 11:00 AM. Early check-in and
              late check-out may be available upon request and may incur additional charges.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-light">4. Payment Terms</h2>
            <p className="text-gray-300">
              We accept major credit cards and payment must be made in full at the time of booking or
              upon check-in, depending on the rate type selected.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-light">5. Cancellation Policy</h2>
            <p className="text-gray-300">
              Cancellation policies vary by rate type. Please refer to your booking confirmation for
              specific cancellation terms. No-shows may be charged the full amount of the stay.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-light">6. Property Rules</h2>
            <p className="text-gray-300">
              Guests are required to follow all property rules and regulations during their stay.
              Violation of these rules may result in eviction without refund.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-light">7. Contact</h2>
            <p className="text-gray-300">
              For any questions regarding these Terms & Conditions, please contact us at{' '}
              <a href="mailto:legal@luxurisuites.com" className="text-[#d7a55a] hover:underline">
                legal@luxurisuites.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
