import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="pt-18 md:pt-24 bg-black min-h-screen">
      <div className="container mx-auto px-4 py-16 text-white">
        <h1 className="text-5xl md:text-7xl font-light mb-12">Privacy Policy</h1>
        <div className="space-y-8 max-w-4xl">
          <section className="space-y-4">
            <h2 className="text-2xl font-light">1. Information We Collect</h2>
            <p className="text-gray-300">
              We collect information that you provide directly to us, including when you make a reservation,
              create an account, sign up for our newsletter, or communicate with us.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-light">2. How We Use Your Information</h2>
            <p className="text-gray-300">
              We use the information we collect to provide, maintain, and improve our services,
              to process your reservations and transactions, and to communicate with you.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-light">3. Information Sharing</h2>
            <p className="text-gray-300">
              We do not sell or rent your personal information to third parties. We may share your
              information with service providers who assist us in operating our business.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-light">4. Security</h2>
            <p className="text-gray-300">
              We implement appropriate technical and organizational measures to protect your personal
              information against unauthorized access, alteration, disclosure, or destruction.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-light">5. Your Rights</h2>
            <p className="text-gray-300">
              You have the right to access, correct, or delete your personal information. You may also
              object to or restrict certain processing of your information.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-light">6. Contact Us</h2>
            <p className="text-gray-300">
              If you have any questions about this Privacy Policy, please contact us at{' '}
              <a href="mailto:privacy@luxurisuites.com" className="text-[#d7a55a] hover:underline">
                privacy@luxurisuites.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
