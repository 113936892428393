/**
 * BlogPost Component
 * Renders a single blog post card with image, title, and date
 * @param {Object} props
 * @param {string} props.title - The title of the blog post
 * @param {string} props.date - The publication date
 * @param {string} props.image - URL of the blog post image
 * @param {string} props.slug - URL slug for the blog post
 */
import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = ({ title, date, image, slug }) => (
  <Link to={`/blog/${slug}`}>
    <div className="bg-white rounded-lg overflow-hidden">
      {/* Blog Post Image Container */}
      <div className="relative w-auto h-[400px] cursor-pointer">
        <img 
          src={image} 
          alt={title} 
          className="rounded-2xl shadow-md w-full h-[400px] object-cover" 
        />
        {/* Overlay gradient */}
        <div className="absolute inset-0 bg-black/40" />
      </div>
      {/* Blog Post Meta Information */}
      <div className="mt-4 space-y-2">
        <p className="text-gray-500">{date}</p>
        <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
      </div>
    </div>
  </Link>
);

export default BlogPost;
