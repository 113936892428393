/**
 * ConciergePage Component
 * Main page component for the Concierge section
 */
import React from 'react';
import ConciergeHero from '../components/ConciergeHero';

const ConciergePage = () => {
  return (
    <div className="bg-black text-white">
      <ConciergeHero />
      {/* Add additional sections here as needed */}
    </div>
  );
};

export default ConciergePage;
