import React from 'react';
import Slider from 'react-slick'; 
import { AREA_ATTRACTIONS, DINNING, SHOPPING , MUSEUMS, BEACHES} from './constants/constants.js';


const Guide = () => {
  return (
    <div>
      <section className="h-[40vh] md:h-[50vh] relative flex items-center justify-center">
        <img
          src="/img/hero.png?height=1080&width=1920"
          alt="Miami Beach"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/70" />
        <div className="relative z-10 text-center text-white space-y-6">
          <h1 className="text-5xl md:text-6xl mt-10 font-regular ">MIAMI BEACH GUIDE</h1>
        </div>
      </section>
      <div className="container mx-auto px-4 py-16">
        <p className="text-lg text-gray-700 text-justify md:text-left">
          Step into the world-renowned South Beach paradise. Experience vibrant nightlife with buzzing bars and clubs that keep their doors open till dawn. Often dubbed as America's Riviera, South Beach's Ocean Drive beckons with its stunning populace, alluring eateries, and charming sidewalk cafes.
        </p>
        <p className="text-lg text-gray-700 mt-4 text-justify md:text-left">
          Whether you're here for leisure or work, Ocean Five Hotel on Ocean Drive ensures you're just steps away from top-notch shopping, breathtaking beaches, attractions, and a scintillating nightlife. The famed Lincoln Road, Miami Beach Convention Centre, a pedestrian haven boasting boutiques, restaurants, galleries, and people-watching opportunities, all lie within a mile of the hotel. Furthermore, the Art Deco District, characterized by its pastel-colored 1930s architecture, awaits just beyond your doorstep. This South Beach getaway promises non-stop thrills!
        </p>
        <p className="text-lg text-gray-700 mt-4 text-justify md:text-left">
          Singles, couples, and families alike can revel in the abundance of attractions. And why wait? South Beach blesses you with year-round summer weather. It's time to seize the moment!
        </p>
      </div>
  
    <div className="flex lg:flex-row flex-col">
          <div className="relative lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto bg-black">
            <img src="/img/guide11.jpg" fill className="p-6 lg:p-16 lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto guide-imgs" alt="" />
          </div>
          <div className="lg:w-[45vw] bg-[#D9D9D9] text-center p-8 md:p-12 flex flex-col justify-center">
            <h3 className="text-5xl pb-10">The Art Deco District</h3>
            <p className="pb-10">
              Situated in South Beach, the Art Deco National District in Miami
              Beach boasts a compact square mile. It hosts the world&apos;s most
              abundant collection of Art Deco structures, showcasing pre-war
              modernism with intricate geometric details and signature curved
              edges.
            </p>
            <p>
              After undergoing meticulous and extensive renovation in recent
              times, these 1920s to early 1940s buildings now shine in their
              original splendor. Within this area, you&apos;ll discover over 800
              officially recognized historic edifices, featuring vibrant hues
              and nostalgic motifs, forming an enchanting seaside panorama.
            </p>
          </div>
        </div>

{/* Area Attractions */}
          
          <div className="flex lg:flex-row-reverse flex-col">
          <div className="relative lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto bg-black">
            <img src={"/img/guide2.png"} fill className="p-6 lg:p-16 lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto guide-imgs" />
          </div>
          <div className="lg:w-[45vw] bg-[#D9D9D9] text-center p-8 md:p-12 flex flex-col justify-center">
            <h3 className="text-5xl pb-10">Area Attractions</h3>
            <Slider
              dots={true}
              noOfSlides={1}
              autoPlay={false}
              infinite={true}
              arrows={true}
            >
              {AREA_ATTRACTIONS.map((t) => (
                <div key={t.title} className="">
                  <div className="pb-6 flex flex-col items-center">
                    <h4 className="text-lg font-semibold">{t.title}</h4>
                    <p className="w-2/3 text-[#757575]">{t.address}</p>
                  </div>

                  <p>{t.data}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>

  {/* Dining */}      
  <div className="flex lg:flex-row flex-col">
          <div className="relative lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto bg-black">
            <img src={"/img/guide3.png"} fill className="p-6 md:p-16 lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto guide-imgs" alt=""/>
          </div>
          <div className="lg:w-[45vw] bg-[#D9D9D9] text-center  p-8 md:p-12  flex flex-col justify-center">
            <h3 className="text-5xl pb-10">Dining</h3>
            <Slider
              dots={true}
              noOfSlides={1}
              autoPlay={false}
              infinite={true}
              arrows={true}
            >
              {DINNING.map((t) => (
                <div key={t.title}>
                  <div className="pb-6 flex flex-col items-center">
                    <h4 className="text-lg font-semibold">{t.title}</h4>
                    <p className="w-2/3 text-[#757575]">{t.address}</p>
                  </div>

                  <p>{t.data}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>

  {/* BEACHES */}
  <div className="flex lg:flex-row-reverse flex-col">
          <div className="relative lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto bg-black">
            <img src={"/img/guide4.png"} fill className="p-6 md:p-16 lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto guide-imgs" alt=""></img>
          </div>
          <div className="lg:w-[45vw] bg-[#D9D9D9] text-center  p-8 md:p-12  flex flex-col justify-center">
            <h3 className="text-5xl pb-10">Beaches</h3>
            <Slider
              dots={true}
              noOfSlides={1}
              autoPlay={false}
              infinite={true}
              arrows={true}
            >
              {BEACHES.map((t) => (
                <div key={t.title}>
                  <div className="pb-6 flex flex-col items-center">
                    <h4 className="text-lg font-semibold">{t.title}</h4>
                    <p className="w-2/3 text-[#757575]">{t.address}</p>
                  </div>

                  <p>{t.data}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/* Museums */}
        <div className="flex lg:flex-row flex-col">
          <div className="relative lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto bg-black">
            <img src={"/img/guide5.png"} fill className="p-6 md:p-16 lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto guide-imgs" alt=""></img>
          </div>
          <div className="lg:w-[45vw] bg-[#D9D9D9] text-center  p-8 md:p-12  flex flex-col justify-center">
            <h3 className="text-5xl pb-10">Museums</h3>
            <Slider
              dots={true}
              noOfSlides={1}
              autoPlay={false}
              infinite={true}
              arrows={true}
            >
              {MUSEUMS.map((t) => (
                <div key={t.title}>
                  <div className="pb-6 flex flex-col items-center">
                    <h4 className="text-lg font-semibold">{t.title}</h4>
                    <p className="w-2/3 text-[#757575]">{t.address}</p>
                  </div>

                  <p>{t.data}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/* SHOPPING */}
        <div className="flex lg:flex-row-reverse flex-col">
          <div className="relative lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto bg-black">
            <img src={"/img/guide6.png"} fill className="p-6 md:p-16 lg:w-[55vw] h-[350px] md:h-[500px] xl:h-[650px] lg:h-auto guide-imgs" alt=""></img>
          </div>
          <div className="lg:w-[45vw] bg-[#D9D9D9] text-center  p-8 md:p-12  flex flex-col justify-center">
            <h3 className="text-5xl pb-10">South Beach Shopping</h3>
            <Slider
              dots={true}
              noOfSlides={1}
              autoPlay={false}
              infinite={true}
              arrows={true}
            >
              {SHOPPING.map((t) => (
                <div key={t.title}>
                  <div className="pb-6 flex flex-col items-center">
                    <h4 className="text-lg font-semibold">{t.title}</h4>
                    <p className="w-2/3 text-[#757575]">{t.address}</p>
                  </div>

                  <p>{t.data}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>


    </div>
  );
};

export default Guide; 