import React from 'react';

const Button = ({ variant = 'default', size = 'default', onClick, className = '', children }) => {
  const baseStyles = 'inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none';
  
  const variants = {
    default: 'bg-white text-black hover:bg-gray-100',
    outline: 'border-2 border-white bg-transparent',
    icon: 'h-10 w-10 p-0'
  };

  const sizes = {
    default: 'h-11 px-8 py-2',
    icon: 'h-10 w-10'
  };

  return (
    <button
      className={`${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;